<template>
  <v-sheet>
    <v-navigation-drawer
      dark
      width="724px"
      right
      absolute
      v-model="drawer"
      style="z-index: 100"
      temporary
      color="#f6f7f8"
    >
      <div
        style="
          background-color: #f6f7f8;
          padding-top: 45px;
          padding-right: 4px;
          overflow-y: scroll;
        "
        v-if="$vuetify.breakpoint.smAndDown"
      >
        <v-row no-gutters justify="end">
          <div class="backgroundIcon" style="position: absolute">
            <div
              class="closeIcon"
              style="cursor: pointer"
              @click="$emit('close')"
            />
          </div>
        </v-row>
      </div>
      <div
        class="backgroundDrawer"
        :style="$vuetify.breakpoint.smAndDown ? 'padding-top: 0px' : ''"
        v-if="!isSentMessage"
      >
        <v-row no-gutters justify="end" v-if="!$vuetify.breakpoint.smAndDown">
          <div class="backgroundIcon" style="position: absolute">
            <div
              class="closeIcon"
              style="cursor: pointer"
              @click="$emit('close')"
            />
          </div>
        </v-row>
        <p class="drawerTitle" style="margin-top: 20px">Contact us</p>
        <p class="drawerText">
          The team is open to your ideas, questions, and needs. Our clients get
          superior results when a short-term acquaintance turns into a long-term
          collaboration.
        </p>
        <form ref="form" @submit.prevent="sendEmail">
          <p class="drawerSectionName">REQUEST A QUOTE</p>
          <v-row no-gutters>
            <v-text-field
              dense
              outlined
              placeholder="Type here.."
              label="Full name"
              height="64px"
              v-model="message.full_name"
              :error-messages="fullnameError"
              background-color="#FBFBFB"
              color="rgba(108, 114, 141, 0.3)"
              :style="
                $vuetify.breakpoint.smAndDown ? '' : 'padding-right: 16px'
              "
              name="full_name"
            />
            <v-text-field
              dense
              outlined
              placeholder="Type here.."
              label="Company (optional)"
              height="64px"
              v-model="message.company"
              background-color="#FBFBFB"
              color="rgba(108, 114, 141, 0.3)"
              :style="$vuetify.breakpoint.smAndDown ? '' : 'padding-left: 16px'"
              name="company"
            />
          </v-row>
          <v-text-field
            dense
            outlined
            placeholder="Type here.."
            label="Email"
            height="64px"
            v-model="message.email"
            :error-messages="emailError"
            background-color="#FBFBFB"
            color="rgba(108, 114, 141, 0.3)"
            name="email"
          />
          <v-autocomplete
            dense
            outlined
            placeholder="Type here.."
            label="Products"
            height="64px"
            v-model="message.product"
            :error-messages="productsError"
            background-color="#FBFBFB"
            color="rgba(108, 114, 141, 0.3)"
            :items="products"
            name="product"
          />
          <p class="drawerSectionName">Messages</p>
          <v-textarea
            dense
            outlined
            placeholder="Type here.."
            label="Describe your question"
            height="120px"
            v-model="message.message"
            :error-messages="messageError"
            background-color="#FBFBFB"
            color="rgba(108, 114, 141, 0.3)"
            name="message"
          />
        </form>
        <v-row no-gutters justify="center" style="margin-top: 18px">
          <button class="requestOfferBtn" @click="sendMessage">
            request offer
          </button>
        </v-row>
        <v-row no-gutters justify="center" style="margin-top: 24px">
          <img
            src="@/assets/img/siteLogo.png"
            width="72px"
            height="50px"
            alt="site logo"
          />
        </v-row>
      </div>
      <div class="backgroundDrawer" style="padding-bottom: 100px" v-else>
        <p class="successMessageTitle">Thank you for choosing us</p>
        <p class="successMessageText">
          Our manager will contact you shortly to clarify the details.
        </p>
        <v-row no-gutters justify="center" style="margin-top: 48px">
          <img
            src="@/assets/img/successSentMessage.png"
            alt="success sent message"
            width="351px"
            height="351px"
          />
        </v-row>
        <v-row no-gutters justify="center" style="margin-top: 48px">
          <button
            class="requestOfferBtn"
            style="width: 190px"
            @click="$emit('close')"
          >
            close
          </button>
        </v-row>
        <v-row no-gutters justify="center" style="margin-top: 80px">
          <img
            src="@/assets/img/siteLogo.png"
            width="72px"
            height="50px"
            alt="site logo"
          />
        </v-row>
      </div>
    </v-navigation-drawer>
  </v-sheet>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import axios from "axios";
export default {
  mixins: [validationMixin],
  data: () => ({
    products: [
      "Steel and metal products",
      "Digital and IT hardware",
      "Polymers",
      "Automotive",
      "Other",
    ],
    message: {
      product: "",
    },
    drawer: true,
    isSentMessage: false,
  }),
  props: {
    productId: {
      require: false,
    },
  },
  validations: {
    message: {
      full_name: {
        required,
      },
      //   company: {
      //     required,
      //   },
      email: {
        required,
        email,
      },
      product: {
        required,
      },
      message: {
        required,
      },
    },
  },
  mounted() {
    if (this.productId !== undefined) {
      this.message.product = this.productId;
    }
  },
  methods: {
    async sendMessage() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let body = `
        <h2> Full name: ${this.message.full_name}</h2>
        <p> Company: ${this.message.company};</p>
        <p> E-mail: ${this.message.email};</p>
        <p> Products: ${this.message.product}</p>
        <p> Question: ${this.message.message};</p>`;
        let form = new FormData();
        form.append("body", body);
        await axios
          .post(`${window.location.href}contact_us.php`, form)
          .then(() => {
            this.isSentMessage = true;
            this.message = {
              product: "",
            };
            this.$v.$reset();
          });
      }
    },
  },
  watch: {
    drawer: {
      handler() {
        if (this.drawer == false) {
          this.$emit("close");
        }
      },
    },
    productId: {
      handler() {
        this.message.product = this.productId;
      },
    },
  },
  computed: {
    fullnameError() {
      const errors = [];
      if (!this.$v.message.full_name.$dirty) {
        return errors;
      }
      !this.$v.message.full_name.required &&
        errors.push("Please fill out the input field");
      return errors;
    },
    emailError() {
      const errors = [];
      if (!this.$v.message.email.$dirty) {
        return errors;
      }
      !this.$v.message.email.required &&
        errors.push("Please fill out the input field");
      if (!this.$v.message.email.email) {
        errors.push("Please fill out the input field");
        return errors;
      }
      return errors;
    },
    // companyError() {
    //   const errors = [];
    //   if (!this.$v.message.company.$dirty) {
    //     return errors;
    //   }
    //   !this.$v.message.company.required &&
    //     errors.push("Please fill out the input field");
    //   return errors;
    // },
    productsError() {
      const errors = [];
      if (!this.$v.message.product.$dirty) {
        return errors;
      }
      !this.$v.message.product.required &&
        errors.push("Please fill out the input field");
      return errors;
    },
    messageError() {
      const errors = [];
      if (!this.$v.message.message.$dirty) {
        return errors;
      }
      !this.$v.message.message.required &&
        errors.push("Please fill out the input field");
      return errors;
    },
  },
};
</script>

<style scoped>
.backgroundIcon {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.closeIcon {
  width: 24px;
  height: 24px;
  background-color: #7d7d7d;
  -webkit-mask: url("@/assets/img/closeIcon.svg") no-repeat center;
  mask: url("@/assets/img/closeIcon.svg") no-repeat center;
  z-index: 10;
}
.backgroundIcon:hover {
  background: rgba(5, 17, 73, 0.1);
  border-radius: 50%;
}
.backgroundIcon:hover .closeIcon {
  background: #2a41db;
}
@media only screen and (min-width: 600px) {
  .backgroundDrawer {
    background: #f6f7f8;
    padding: 72px 40px 17px 40px;
  }
  .drawerTitle {
    font-weight: 500;
    font-size: 64px;
    line-height: 77px;
    background: linear-gradient(
      88.25deg,
      #1e43ed -35.09%,
      rgba(16, 6, 159, 0.72) 109.83%,
      #fbfbfb 109.84%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  .drawerText {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #1d1d1d;
  }
  .drawerSectionName {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #1d1d1d;
    text-transform: uppercase;
    margin-top: 48px;
    margin-bottom: 20px;
  }
  .requestOfferBtn {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
    color: #1d1d1d;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 21px 12px;
    width: 300px;
    height: 60px;
    border: 1.5px solid #100e1d;
    border-radius: 20px;
    transition: all 0.3s ease-in-out;
    user-select: none;
    z-index: 90;
  }
  .requestOfferBtn:hover {
    background: linear-gradient(87.79deg, #2a4dee -11.28%, #050e37 94.42%);
    color: #fbfbfb;
  }
  .successMessageTitle {
    font-weight: 500;
    font-size: 96px;
    line-height: 115px;
    background: linear-gradient(
      88.25deg,
      #1e43ed -35.09%,
      rgba(16, 6, 159, 0.72) 109.83%,
      #fbfbfb 109.84%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-bottom: 20px;
  }
  .successMessageText {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #1d1d1d;
  }
}
@media only screen and (max-width: 599px) {
  .backgroundDrawer {
    background: #f6f7f8;
    padding: 72px 40px 17px 40px;
  }
  .drawerTitle {
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;
    background: linear-gradient(
      88.25deg,
      #1e43ed -35.09%,
      rgba(16, 6, 159, 0.72) 109.83%,
      #fbfbfb 109.84%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  .drawerText {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #1d1d1d;
  }
  .drawerSectionName {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #1d1d1d;
    text-transform: uppercase;
    margin-top: 48px;
    margin-bottom: 20px;
  }
  .requestOfferBtn {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
    color: #1d1d1d;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 21px 12px;
    width: 100% !important;
    height: 60px;
    border: 1.5px solid #100e1d;
    border-radius: 20px;
    transition: all 0.3s ease-in-out;
  }
  .requestOfferBtn:hover {
    background: linear-gradient(87.79deg, #2a4dee -11.28%, #050e37 94.42%);
    color: #fbfbfb;
  }
  .successMessageTitle {
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;
    background: linear-gradient(
      88.25deg,
      #1e43ed -35.09%,
      rgba(16, 6, 159, 0.72) 109.83%,
      #fbfbfb 109.84%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-bottom: 20px;
  }
  .successMessageText {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #1d1d1d;
  }
}
</style>
<style>
.v-text-field--outlined.v-input--dense .v-label {
  color: #1d1d1d;
}
.v-select.v-text-field:not(.v-text-field--single-line) input {
  color: #161616;
  margin-top: 0px;
}
.v-text-field input::placeholder {
  color: #161616 !important;
}
.v-textarea input::placeholder {
  color: #161616 !important;
}
.theme--dark.v-input,
.theme--dark.v-input input,
.theme--dark.v-input textarea {
  color: #161616 !important;
}
</style>