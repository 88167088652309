<template>
  <v-navigation-drawer
    class="navigationDrawer"
    absolute
    permanent
    right
    width="100%"
    height="100svh"
    v-model="navigationDrawer"
  >
    <v-row no-gutters>
      <img
        src="@/assets/img/siteLogo.png"
        alt="site logo"
        width="85px"
        height="60px"
      />
      <v-spacer />
      <img
        src="@/assets/img/closeIcon.svg"
        alt="menu icon"
        @click="$emit('close')"
      />
    </v-row>
    <v-col style="text-align: center; margin-top: 30%">
      <p
        class="headerItem"
        v-for="item in headerItems"
        :key="item.id"
        @click="toPage(item.href)"
      >
        {{ item.title }}
      </p>
    </v-col>
    <v-row no-gutters justify="center" style="margin-top: 30%">
      <div class="requestOfferBtn" @click="$emit('showRequestForm')">
        request offer
      </div>
    </v-row>
    <v-row no-gutters align="center" justify="center" style="margin-top: 26px">
      <span class="phoneNumber"> +380987654370 </span>
      <a
        href="https://www.viber.com/add?number=+380987654370"
        style="margin-left: 10px"
      >
        <img src="@/assets/img/viberIcon.svg" />
      </a>
      <a href="https://wa.me/+380987654370" style="margin-left: 10px">
        <img src="@/assets/img/whatssAppIcon.svg" />
      </a>
    </v-row>
  </v-navigation-drawer>
</template>

<script>
import { eventBus } from "@/main";
export default {
  data: () => ({
    navigationDrawer: true,
  }),
  props: {
    headerItems: {
      require: true,
    },
  },
  methods: {
    toPage(id) {
      this.$emit("close");
      eventBus.$emit("toPage", id);
    },
  },
  watch: {
    navigationDrawer: {
      handler() {
        if (this.navigationDrawer == false) {
          this.$emit("close");
        }
      },
    },
  },
};
</script>

<style scoped>
.navigationDrawer {
  background: rgba(244, 245, 247, 1) !important;
  border-bottom: 1px solid rgba(108, 114, 141, 0.3);
  backdrop-filter: blur(5px);
  padding: 40px 16px;
  z-index: 100;
}
.headerItem {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  color: #3f3f3f;
  cursor: pointer;
  user-select: none;
  margin-bottom: 36px;
}
.headerItem:nth-child(3) {
  margin-right: 0px;
}
.phoneNumber {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #152c94;
}
.requestOfferBtn {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: #1d1d1d;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 21px 12px;
  width: 190px;
  height: 60px;
  border: 1.5px solid #100e1d;
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
  user-select: none;
}
.requestOfferBtn:hover {
  background: linear-gradient(87.79deg, #2a4dee -11.28%, #050e37 94.42%);
  color: #fbfbfb;
}
</style>