<template>
  <v-app-bar height="120px" class="header">
    <v-main>
      <v-container>
        <v-row no-gutters align="center" v-if="$vuetify.breakpoint.mdAndUp">
          <v-col
            cols="1"
            xl="2"
            lg="1"
            md="1"
            sm="1"
            style="text-align: right"
            class="py-0"
          >
            <img
              src="@/assets/img/siteLogo.png"
              alt="site logo"
              width="143px"
              height="100px"
              class="siteLogo"
              style="cursor: pointer"
              @click="$router.go()"
            />
          </v-col>
          <v-col cols="6" xl="5" lg="6" md="7" style="padding-right: 40px">
            <v-row no-gutters justify="end">
              <span
                class="headerItem inActiveHeaderItem"
                v-for="item in headerItems"
                :key="item.id"
                @click="toPage(item.href)"
              >
                {{ item.title }}
              </span>
            </v-row>
          </v-col>
          <v-col
            cols="2"
            xl="2"
            lg="2"
            md="2"
            style="text-align: center"
            :style="$vuetify.breakpoint.xl ? 'padding-right: 40px' : ''"
          >
            <v-row
              no-gutters
              align="center"
              v-if="$vuetify.breakpoint.lgAndUp"
              justify-xl="end"
            >
              <a href="tel:+380504305556" style="text-decoration: none">
                <span class="phoneNumber">+380504305556</span></a
              >
              <a
                href="https://www.viber.com/add?number=+380504305556"
                style="margin-left: 10px"
              >
                <img src="@/assets/img/viberIcon.svg" alt="viber" />
              </a>
              <a href="https://wa.me/+380504305556" style="margin-left: 10px">
                <img src="@/assets/img/whatssAppIcon.svg" alt="whatsapp" />
              </a>
            </v-row>
            <v-col v-else>
              <span class="phoneNumber">+380504305556</span>
              <v-row no-gutters justify="center">
                <a
                  href="https://www.viber.com/add?number=+380504305556"
                  style="margin-left: 10px"
                >
                  <img src="@/assets/img/viberIcon.svg" alt="viber" />
                </a>
                <a
                  href="https://wa.me/+380504305556"
                  style="margin-left: 10px"
                >
                  <img src="@/assets/img/whatssAppIcon.svg" alt="whatsapp" />
                </a>
              </v-row>
            </v-col>
          </v-col>
          <v-col cols="2">
            <button class="requestOfferBtn" @click="$emit('showRequestForm')">
              request offer
            </button>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-row no-gutters>
            <img
              src="@/assets/img/siteLogo.png"
              alt="site logo"
              width="85px"
              height="60px"
            />
            <v-spacer />
            <img
              src="@/assets/img/burgerIcon.svg"
              alt="menu icon"
              @click="$emit('showNavigationDrawer')"
            />
          </v-row>
        </v-row>
      </v-container>
    </v-main>
  </v-app-bar>
</template>

<script>
//import mobileNavigationDrawer from "./mobileNavigationDrawer.vue";
import { eventBus } from "@/main";
export default {
  //components: { mobileNavigationDrawer },
  name: "HeaderComponent",
  data: () => ({
    headerItems: [
      {
        id: 1,
        title: "About us",
        href: "#about_us",
      },
      {
        id: 2,
        title: "products AND SERVICES",
        href: "#product_and_services",
      },
      {
        id: 3,
        title: "Contacts",
        href: "#contacts",
      },
    ],
    showNavigationDrawer: false,
  }),
  methods: {
    toPage(id) {
      eventBus.$emit("toPage", id);
    },
  },
};
</script>

<style scoped>
.header {
  background: #f4f5f7 !important;
  border-bottom: 1px solid rgba(108, 114, 141, 0.3);
  backdrop-filter: blur(1px);
  z-index: 100;
  overflow: hidden;
}
.headerItem {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  color: #3f3f3f;
  margin-right: 40px;
  cursor: pointer;
  user-select: none;
  border-bottom: 2px solid transparent;
}
.inActiveHeaderItem:after {
  display: block;
  content: "";
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
  border-bottom: 2px solid #1e43ed;
}
.headerItem:hover:after {
  transform: scaleX(1);
  transform-origin: 0% 50%;
}
.headerItem:nth-child(3) {
  margin-right: 0px;
}
.phoneNumber {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #152c94;
}
.requestOfferBtn {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: #1d1d1d;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 21px 12px;
  width: 190px;
  height: 60px;
  border: 1.5px solid #100e1d;
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
}
.requestOfferBtn:hover {
  background: linear-gradient(87.79deg, #2a4dee -11.28%, #050e37 94.42%);
  color: #fbfbfb;
}

@media only screen and (max-width: 1240px) and (min-width: 768px) {
  .requestOfferBtn {
    width: 140px !important;
  }
  .siteLogo {
    width: 70px;
    height: 50px;
  }
}
</style>