import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'main',
        component: () => import('@/components/Main/mainComponent.vue'),
    },
    { path: '*', redirect: 'https://' + window.location.host + '/' }
];
const router = new VueRouter({
    mode: 'history',
    routes,
});

export default router;