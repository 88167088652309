<template>
  <v-footer
    :height="$vuetify.breakpoint.smAndDown ? '300px' : '160px'"
    color="white"
    style="padding: 40px 0px; overflow: hidden"
    :style="$vuetify.breakpoint.smAndDown ? 'padding-bottom: 20px' : ''"
  >
    <v-main>
      <v-container>
        <v-col v-if="$vuetify.breakpoint.smAndDown" style="text-align: center">
          <img
            src="@/assets/img/siteLogo.png"
            alt="site logo"
            width="115px"
            height="80px"
          />
          <p class="footerText" style="margin-top: 20px">
            © NRM SE. All rights reserved
          </p>
          <v-row
            no-gutters
            justify="center"
            align="center"
            style="margin-top: 55px"
          >
            <span class="developByLabel">Developed by</span>
            <a href="https://bmax.com.ua/" target="_blank">
              <img
                src="@/assets/img/BMaxLogo.png"
                width="80px"
                height="40px"
                alt="Bmax logo"
              />
            </a>
          </v-row>
        </v-col>
        <v-row no-gutters align="end" v-else>
          <img
            src="@/assets/img/siteLogo.png"
            alt="site logo"
            width="115px"
            height="80px"
          />
          <span class="footerText" style="margin-left: 12px"
            >© NRM SE. All rights reserved</span
          >
          <v-row no-gutters justify="end" align="center">
            <span class="developByLabel">Developed by</span>
            <a href="https://bmax.com.ua/" target="_blank">
              <img
                src="@/assets/img/BMaxLogo.png"
                width="80px"
                height="40px"
                alt="Bmax logo"
              />
            </a>
          </v-row>
        </v-row>
      </v-container>
    </v-main>
  </v-footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style scoped>
.footerText {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  color: #3d3d3d;
}
.developByLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #3d3d3d;
  margin-right: 7px;
}
</style>